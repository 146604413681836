import React, { useState, useEffect } from "react"
import { Route, Redirect, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { auth, db } from "../firebase"

import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  const [userDetails, setUserDetails] = useState('');
  const history = useHistory();

  const [ render, setRender ] = useState(false);

  const fetchUser = async () => {
    await db.collection('users').doc(currentUser.uid).get().then(snapshot => {
      let userData = snapshot.data();

      console.log(userData.role);

      setUserDetails(snapshot.data());
      setRender(true);

      if( userData.role == 'user'){
        history.push('/qr_scanner');
      } else if ( userData.role == 'admin' ) {
        history.push('/');
      } else {
        history.push('/login');
      }
    }).catch(
        error => {
          console.log(error);
          history.push('/login');
        }
    );
  }

  useEffect(() => {
    fetchUser();
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        return render ? <Component {...props} /> : <div/>
      }}
    ></Route>
  )
}
